// Settings
// =====================================================

// Config
$colorBase: #5B8131;
// $colorBase: #98be5b;

$colorBlack: #000000;
$colorGray: #c4c4c4;
$colorGrayL: #f1f1f1;
$colorWhite: #ffffff;
$colorRed: #cb3b3c;
$colorBlue: #007BBE;
$colorYellow: #f3e100;
$colorFont: #444;
$colorPink: #ce2c77;
$colorGold:#DAB300;
$colorSilver:#969A98;
$colorCopper:#AE6938;

$baseWidth: 1110px;
$baseRadius:0.2em;
$webFont: "Lato";
$baseSpace: 15px;
$baseFontSize:24;
$baseFontSizePC:$baseFontSize + 6;

$breakpoints: (
    "sm": "screen and (min-width: 576px)",
    "md": "screen and (min-width: 768px)",
    "lg": "screen and (min-width: 992px)",
    "xl": "screen and (min-width: 1200px)"
) !default;
