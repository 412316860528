// Mixins
// =====================================================

// MediaQuery
@mixin mq($breakpoint: md) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

// Position Center-Middle
@mixin center-middle() {
    // 親要素にposition:relative;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

// Fontsize

@mixin font-size($size) {
    font-size: $size / 16 + rem;
}

// Webfont
@mixin webfont() {
    font-family: $webFont, sans-serif;
}

// Serif
@mixin serif() {
    font-family: 'Times New Roman', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3',
        'Hiragino Mincho ProN', 'メイリオ', Meiryo, serif;
    font-weight: 600;
}

// Margin
@mixin mg-top-btm($multipleT, $multipleB) {
    margin-top: $baseSpace * $multipleT;
    margin-bottom: $baseSpace * $multipleB;
}

@mixin mg-top($multipleT) {
    margin-top: $baseSpace * $multipleT;
}

@mixin mg-btm($multipleB) {
    margin-bottom: $baseSpace * $multipleB;
}

@mixin mg-left($multipleL) {
    margin-left: $baseSpace * $multipleL;
}

@mixin mg-right($multipleR) {
    margin-right: $baseSpace * $multipleR;
}

// Padding

@mixin pd-all($multipleA) {
    padding: $baseSpace * $multipleA;
}

@mixin pd-top-btm($multipleT, $multipleB) {
    padding-top: $baseSpace * $multipleT;
    padding-bottom: $baseSpace * $multipleB;
}

@mixin pd-top($multipleT) {
    padding-top: $baseSpace * $multipleT;
}

@mixin pd-btm($multipleB) {
    padding-bottom: $baseSpace * $multipleB;
}

@mixin pd-left($multipleL) {
    padding-left: $baseSpace * $multipleL;
}

@mixin pd-right($multipleR) {
    padding-right: $baseSpace * $multipleR;
}

// Boxshadow
@mixin box-shadow($x, $y, $blur, $color) {
    box-shadow: $x + px $y + px $blur + px $color;
}

// Boxshadow Inner
@mixin box-shadow-i($x, $y, $blur, $color) {
    box-shadow: inset $x + px $y + px $blur + px $color;
}

// Linear Gradient 下から上
@mixin gradient($btm-color, $top-color) {
    background-color: $btm-color;
    background-image: linear-gradient(to top, $btm-color, $top-color);
}

// Title
@mixin sectionTitle() {
    margin-top: 0;
    margin-bottom: 1.2em;
    word-wrap: break-word;
    @include font-size(25);
    font-weight: normal;
    @include mq(md) {
        @include font-size(32);
    }
}

@mixin pageTitle() {
    font-weight: normal;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: .5em;
    word-wrap: break-word;
    @include font-size(30);

    @include mq(lg) {
        @include font-size(40);
    }

    a {
        color: $colorFont;
    }
}

@mixin selectBase($fontSize) {
    display: inline-block;
    box-shadow: none;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Ctitle%3Earw-down%3C%2Ftitle%3E%3Cpath%20d%3D%22M16%2024l-14.7-14.7%201.3-1.3%2013.4%2013.4%2013.4-13.4%201.3%201.3z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: center right 8px;
    background-color: $colorWhite;
    border-radius: 0;
    border: 1px solid $colorGray;
    // border-radius: .2em;
    padding: .3em 1.5em .3em .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: $fontSize / 16+rem;
    &::-ms-expand {
    display: none;
    };
}

// ボタン基本
@mixin btnBase() {
    // display: inline-block;
    border: 1px solid $colorBlack;
    padding: 0.2em 1em;
    background-color: $colorWhite;
    color: $colorFont;
    text-decoration: none;
    line-height: 1.3;
    position: relative;
    transition: all 0.2s ease-in-out;

    &:after {
        content: "";
        width: 0.5em;
        height: 0.5em;
        display: block;
        position: absolute;
        border-top: 1px solid $colorBlack;
        border-right: 1px solid $colorBlack;
        transform: translateY(-50%) rotate(45deg);
        right: 0.5em;
        top: 50%;
        transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
        background-color: $colorBase;
        color: $colorWhite;

        &:after {
            border-color: $colorWhite;
            right: 0.1em;
        }
    }
}

// ボタン濃色
@mixin btnPrimary($fz) {
        border-radius: 0;
        @include font-size($fz);
        background-color: $colorBase;
        transition: all .2s ease-in-out;
        color:$colorWhite;
        border: 0;

        &:hover,
        &:focus {
            opacity: 0.8;
        }
}

@mixin btnSecondary() {
    color: $colorFont;
    border: 2px solid $colorBlack;
    // border-radius: $baseBorderRadius;

    &:hover,
    &:focus {
        color: $colorWhite;
    }

    &:after {
        background-color: $colorBlack;
    }
}

@mixin btnTertiary() {
    color: $colorFont;
    background-color: $colorWhite;

    &:after {
        background-color: $colorBase1;
    }
}

@mixin aboutTitle() {
    position: relative;

    margin-bottom: 1.2em;
    padding-bottom: .7em;

    text-align: center;

    @include font-size(20);

    &:after {
        position: absolute;
        bottom: 0;
        left: 50%;

        display: block;

        width: 80px;
        height: 3px;

        content: '';
        transform: translateX(-50%);

        background-color: $colorBlack;
    }
}

// 未使用

@function get_vw($size, $viewport: 320) {
    $rate: 100 / $viewport;
    @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10) {
    font-size: $font_size * 1px;
    font-size: get_vw($font_size);
}

// Transition
@mixin transition($time: .7, $easing: ease) {
    transition: all $time + s $easing;
}

// Position Absolute Top-Left
@mixin posa-tl($top: 0, $left: 0) {
    position: absolute;
    top: $top + px;
    left: $left + px;
}

// Position Absolute Top-Right
@mixin posa-tr($top: 0, $right: 0) {
    position: absolute;
    top: $top + px;
    right: $right + px;
}

// Position Absolute Bottom-Left
@mixin posa-bl($bottom: 0, $left: 0) {
    position: absolute;
    bottom: $bottom + px;
    left: $left + px;
}

// Position Absolute Bottom-Right
@mixin posa-br($bottom: 0, $right: 0) {
    position: absolute;
    right: $right + px;
    bottom: $bottom + px;
}

// Position Center-Middle
@mixin v-middle() {
    position: relative;
    top: 50%;

    transform: translateY(-50%);
}

// 矢印下
@mixin arrow($size, $border, $rotate, $color) {
    display: block;

    width: $size + px;
    height: $size + px;

    content: '';
    transform: rotate(#{$rotate}deg);

    border: $border + px solid transparent;
    border-right-color: $color;
    border-bottom-color: $color;
}

@mixin icon($size, $image) {
    position: absolute;
    top: 50%;
    left: 0;

    display: block;

    width: $size + px;
    height: $size + px;

    content: '';
    transform: translateY(-50%);

    background: url(#{$image}) no-repeat center / 100%;
}

// 記事メタ情報

@mixin entryMeta($baseFontSize) {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    order: 1;
    @extend %layoutList;
    @include webfont;

    &_Item {
        margin-right: .5em;
        margin-bottom: .3em;
    }

    &_Date {
        font-size: $baseFontSize / 16 + rem;

        @include mq(md) {
            font-size: ($baseFontSize / 16) * 1.2 + rem;
        }
    }

    &_Category {
        font-size: ($baseFontSize / 16) * .93 + rem;

        @include mq(md) {
            font-size: ($baseFontSize / 16) * 1.1 + rem;
        }

        &:before {
            display: inline-block;

            width: 5px;
            height: 5px;
            margin-right: .3em;

            content: '';
            transform: rotate(45deg) translateY(-.2em);

            background-color: $colorBlack;
        }
    }

    &_Tags {
        font-size: ($baseFontSize / 16) * .93 + rem;

        @include mq(md) {
            font-size: ($baseFontSize / 16) * 1.1 + rem;
        }

        &:before {
            display: inline-block;

            width: 5px;
            height: 5px;
            margin-right: 1em;

            content: '';
            transform: rotate(45deg) translateY(-.2em);

            background-color: $colorBlack;
        }
    }
}
