@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  z-index: 500;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.home-FeatItems .slick-track {
  margin-left: 0;
  margin-right: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../plugins/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.unit-Slider .slick-prev, .unit-Slider
.slick-next {
  width: 25px;
  height: 50px;
}

.home-FeatList .slick-prev, .home-FeatList
.slick-next {
  top: 38%;
  width: 15px;
  height: 30px;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 1;
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  z-index: 501;
  left: -25px;
}

.unit-Slider .slick-prev {
  left: 10px;
}

.home-FeatList .slick-prev {
  left: 0px;
}

@media screen and (min-width: 992px) {
  .home-FeatList .slick-prev {
    left: -15px;
  }
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Ctitle%3Earw-left%3C%2Ftitle%3E%3Cpath%20d%3D%22M8%2016l14.7-14.7%201.3%201.3-13.4%2013.4%2013.4%2013.4-1.3%201.3z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
  z-index: 502;
}

.unit-Slider .slick-next {
  right: 10px;
}

.home-FeatList .slick-next {
  right: 0px;
}

@media screen and (min-width: 992px) {
  .home-FeatList .slick-next {
    right: -15px;
  }
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Ctitle%3Earw-right%3C%2Ftitle%3E%3Cpath%20d%3D%22M24%2016l-14.7%2014.7-1.3-1.3%2013.4-13.4-13.4-13.4%201.3-1.3z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -10px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.unit-Slider .slick-dots {
  bottom: 2%;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .unit-Slider .slick-dots {
    bottom: 15px;
  }
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .unit-Slider .slick-dots li {
    margin: 0 5px;
  }
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 9px;
  line-height: 20px;
  text-align: center;
  color: #c4c4c4;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unit-Slider .slick-dots li button:before {
  color: #ffffff;
}

.slick-dots li.slick-active button:before {
  color: #5B8131;
  opacity: 1;
}
